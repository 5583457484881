








































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NotFound extends Vue {
	goBack() {
		this.$router.go(-1);
	}

	get isHistory(): number {
		return window.history.length;
	}
}
